import React, { useState } from "react";

import SideNav from "./SideNav";
import { useNavigate } from "react-router-dom";
import { CustomIcon } from "./Icons";
import { useDispatch } from "react-redux";
import { LogoutUser } from "../redux/slices/AuthSlice";
import Swal from "sweetalert2";

const Container = ({ mainStyle, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleBar, setToggleBar] = useState(false);

  const changeToggle = () => {
    setToggleBar(!toggleBar);
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out of your account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#050531",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log me out!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Logged out!",
          text: "You have been logged out successfully.",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#050531",
          customClass: {
            confirmButton: "swal2-confirm-custom",
          },
        }).then(() => {
          dispatch(LogoutUser());
          navigate("/login");
        });
      }
    });
  };

  const goToProfileScreen = () => navigate("/profile");

  return (
    <div className="flex flex-nowrap w-full h-full">
      <SideNav toggleBar={toggleBar} toggle={changeToggle} />
      <div
        className={`${
          toggleBar ? "w-full lg:w-[94%]" : "w-full lg:w-[80%]"
        } transition-all duration-500 h-full`}
      >
        <header className="bg-[#4D4D4D] p-2 sm:p-5 flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div
              onClick={changeToggle}
              className="bg-[#FFFFFF] p-2 shadow-sm rounded-[10px] md:px-4 cursor-pointer"
            >
              <CustomIcon
                name={"ic:round-menu"}
                fontSize={33}
                className="!text-[#707070]"
              />
            </div>
            <p className="text-white text-xl md:text-[30px] font-light">
              Create Tests
            </p>
          </div>
          <div className="flex items-center gap-8">
            <div
              onClick={goToProfileScreen}
              className="flex items-center justify-center gap-2 cursor-pointer"
            >
              <CustomIcon name={"fa-solid:home"} fontSize={20} />
              <p className="text-white text-[16px] sm:text-[18px] font-light  md:block hidden">
                My Account
              </p>
            </div>
            <div
              onClick={handleLogout}
              className="flex items-center gap-2 cursor-pointer"
            >
              <CustomIcon name={"heroicons-outline:logout"} fontSize={24} />
              <p className="text-white text-[18px] font-light  md:block hidden">
                Log out
              </p>
            </div>
          </div>
        </header>

        <div className={`${mainStyle}`}>{children}</div>
      </div>
    </div>
  );
};

export default Container;
