import React from "react";

const ButtonWithLoading = ({
  onClick,
  isLoading,
  disabled,
  buttonText,
  className,
  type,
  bgColor = "#000B22",
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`w-full text-white text-[20px] p-4 outline-none rounded-md flex items-center justify-center ${className}`}
      disabled={isLoading || disabled}
      style={{ backgroundColor: bgColor }} 
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          <svg
            className="animate-spin h-5 w-5 text-white mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
            ></path>
          </svg>
          Processing...
        </div>
      ) : (
        buttonText
      )}
    </button>
  );
};

export default ButtonWithLoading;
