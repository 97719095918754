// src/redux/subscriptionSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSubscribed: false, // Track subscription status
  showModal: false,    // Track modal visibility
  step: 1,             // Step 1: PlanCard, Step 2: Form
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    openModal: (state) => {
      state.showModal = true;
    },
    closeModal: (state) => {
      state.showModal = false;
    },
    subscribeUser: (state) => {
      state.isSubscribed = true; // Mark user as subscribed
      state.showModal = false;   // Close modal after subscription
    },
    moveToForm: (state) => {
      state.step = 2;  // Move to subscription form after selecting a plan
    },
    resetStep: (state) => {
      state.step = 1;  // Reset to plan selection if needed
    },
  },
});

export const { openModal, closeModal, subscribeUser, moveToForm, resetStep } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
