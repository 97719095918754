import React from 'react'

import { useNavigate } from 'react-router-dom'

import Container from '../../Components/Container'
import CardHeader from '../../Components/CardHeader'
import SettingCard from '../../Components/SettingCard'
import getContents from '../../actions/getContent'

function TermsConditon() {
  const navigate = useNavigate();
  const [content, setContent] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await getContents({ slug: "terms-conditions" });
        setContent(response);
      } catch (error) {
        console.log(error);
        // setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, []);
  return (
   <Container>
    <div
      className={`w-full h-full p-3 sm:p-6 md:px-12 md:p-8 bg-[#FFFFFF]`}
      key={content?.[0]?.id}
      // style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
    >
      {" "}
      <CardHeader header={content?.[0]?.title} onClick={() => navigate(-1)} />
      <SettingCard
        header=""
        paragraph={content?.[0]?.value}
      />
    </div>
   </Container>
  )
}

export default TermsConditon
