import { IMAGES } from "./Images";

export const CheckboxData = [
  {
    name: " Abdominal Pain",
  },

  {
    name: "Advanced Cardiac Life Support",
  },

  {
    name: "Allergy",
  },

  {
    name: " Anemia",
  },

  {
    name: " Antibiotics ",
  },

  {
    name: "Anxiety ",
  },

  {
    name: "Asthma ",
  },

  {
    name: "Atrial Fibrillation",
  },

  {
    name: " Bad News ",
  },
  {
    name: " Behavioral Problems ",
  },

  {
    name: "Breast Lump ",
  },

  {
    name: "Cancer",
  },

  {
    name: "Chest Pain ",
  },

  {
    name: "Chronic Disease ",
  },

  {
    name: "Chronic Obstructive Pulmonary Disease ",
  },
  {
    name: " Chronic Pain ",
  },

  {
    name: "Contraception",
  },

  {
    name: " Cough  ",
  },

  {
    name: "Counselling",
  },

  {
    name: "Crisis ",
  },

  {
    name: "  Croup",
  },

  {
    name: "Deep Venous Thrombosis ",
  },

  {
    name: "  Dehydration  ",
  },

  {
    name: " Dementia",
  },

  {
    name: "Depression",
  },

  {
    name: "Diabetes",
  },

  {
    name: "Diarrhea",
  },
  {
    name: " Difficult Patient ",
  },
  {
    name: "Disability",
  },
  {
    name: "Dizziness",
  },
  {
    name: "Domestic Violence",
  },
  {
    name: "Dyspepsia",
  },
  {
    name: "Dysuria",
  },
  {
    name: "Earache",
  },
  {
    name: "   Eating Disorders",
  },
  {
    name: "   Elderly",
  },

  {
    name: "Epistaxis",
  },
  {
    name: " Family Issues",
  },
  {
    name: "Fatigue",
  },
  {
    name: "Fever",
  },
  {
    name: "Fractures",
  },
  {
    name: "  Gastro-intestinal Bleed ",
  },
  {
    name: "Gender Specific Issues",
  },
  {
    name: "Grief",
  },
  {
    name: "Headache",
  },
  {
    name: "Heart Failure",
  },
  {
    name: "Hepatitis",
  },
  {
    name: "  Hyperlipidemia  ",
  },

  {
    name: "Hypertension",
  },

  {
    name: "Immigrants",
  },

  {
    name: "Immunization",
  },

  {
    name: "In Children ",
  },

  {
    name: "Infections",
  },
  {
    name: "Infertility",
  },

  {
    name: "Insomnia",
  },
  {
    name: "Ischemic",
  },
  {
    name: " Heart Disease",
  },
  {
    name: "Joint Disorder",
  },
  {
    name: "Lacerations",
  },

  {
    name: "Learning (Patients/Self)",
  },
  {
    name: "Lifestyle",
  },
  {
    name: " Loss of Consciousness",
  },
  {
    name: " Loss of Weight",
  },
  {
    name: "Low-back Pain",
  },

  {
    name: "Meningitis",
  },

  {
    name: "Menopause",
  },
  {
    name: "Mental Competency",
  },

  {
    name: "Multiple Medical Problems",
  },

  {
    name: " Neck Pain",
  },
  {
    name: "Newborn",
  },

  {
    name: "Obesity",
  },

  {
    name: "Osteoporosis",
  },

  {
    name: "Pain",
  },

  {
    name: " Palliative Care ",
  },

  {
    name: "Parkinsonism",
  },
  {
    name: "Osteoporosis",
  },

  {
    name: "Periodic Health",
  },
  {
    name: " Assessment/Screening",
  },

  {
    name: "Personality Disorder ",
  },
  {
    name: "Pneumonia",
  },

  {
    name: "Poisoning",
  },

  {
    name: "Pregnancy ",
  },

  {
    name: "Prostate",
  },
  {
    name: " Rape/Sexual Assault ",
  },

  {
    name: "Rash ",
  },
  {
    name: "Red Eye",
  },

  {
    name: "Renal Failure ",
  },

  {
    name: "Schizophrenia ",
  },

  {
    name: "Seizures",
  },
  {
    name: "Sex ",
  },

  {
    name: " Sexually Transmitted",
  },
  {
    name: " Infections  ",
  },

  {
    name: "Shortness of Breath",
  },
  {
    name: " Skin Disorder  ",
  },

  {
    name: "Smoking Cessation  ",
  },

  {
    name: "  Somatization",
  },

  {
    name: "Stress  ",
  },

  {
    name: "  Stroke",
  },

  {
    name: "    Substance Use and ",
  },

  {
    name: "Addiction  ",
  },

  {
    name: " Suicide",
  },
  {
    name: " Thyroid ",
  },

  {
    name: "Trauma   ",
  },

  {
    name: "  Travel Medicine ",
  },
  {
    name: "  Upper Respiratory Tract",
  },

  {
    name: " Infection ",
  },

  {
    name: "  Urinary Tract Infection   ",
  },
  {
    name: "  Vaginal Bleeding",
  },

  {
    name: " Vaginitis  ",
  },

  {
    name: "  Violent/Aggressive Patient  ",
  },
  {
    name: "  Well-baby Care",
  },
];

export const startTestData = [
  {
    name: "First name:",
    response: "John",
  },
  {
    name: "Last name:",
    response: "Smith",
  },
  {
    name: "Test name:",
    response: "FME_Demo",
  },
  {
    name: "Language:",
    response: "English",
  },
];

export const startTestImageData = [
  {
    image: IMAGES.BOX,
  },
  {
    image: IMAGES.BOX,
  },
  {
    image: IMAGES.BOX,
  },
  {
    image: IMAGES.BOX,
  },
  {
    image: IMAGES.BOX,
  },

  {
    image: IMAGES.BOX,
  },
  {
    image: IMAGES.BOX,
  },
  {
    image: IMAGES.BOX,
  },
  {
    image: IMAGES.BOX,
  },
  {
    image: IMAGES.BOX,
  },
];

export const startTestSections = [
  {
    id: "1",
    heading: "Welcome to the Tutorial",
    contentOne: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    contentTwo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    description: 'Click on the "Next" button to continue',
  },
  {
    id: "2",
    heading: "Welcome to the Tutorial",
    contentOne: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    contentTwo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    description: 'Click on the "Next" button to continue',
  },
  {
    id: "3",
    heading: "Welcome to the Tutorial",
    contentOne: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    contentTwo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    description: 'Click on the "Next" button to continue',
  },
  {
    id: "4",
    heading: "Welcome to the Tutorial",
    contentOne: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    contentTwo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    description: 'Click on the "Next" button to continue',
  },
  {
    id: "5",
    heading: "Welcome to the Tutorial",
    contentOne: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    contentTwo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    description: 'Click on the "Next" button to continue',
  },
  {
    id: "6",
    heading: "Welcome to the Tutorial",
    contentOne: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    contentTwo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    description: 'Click on the "Next" button to continue',
  },
  {
    id: "7",
    heading: "Welcome to the Tutorial",
    contentOne: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    contentTwo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    description: 'Click on the "Next" button to continue',
  },
  {
    id: "8",
    heading: "Welcome to the Tutorial",
    contentOne: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    contentTwo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    description: 'Click on the "Next" button to continue',
  },
  {
    id: "9",
    heading: "Welcome to the Tutorial",
    contentOne: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    contentTwo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    description: 'Click on the "Next" button to continue',
  },
  {
    id: "10",
    heading: "Welcome to the Tutorial",
    contentOne: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    contentTwo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    description: 'Click on the "Next" button to continue',
  },
];

export const startTestSectionsTwo = [
  {
    id: "1",
    heading: "Question 1",
    description:
      "List other symptoms, not mentioned above, that you should specifically enquire about to help rule out sinister medical causes for her fatigue. List TWO.",
  },
  {
    id: "2",
    heading: "Question 2",
    description:
      "List other symptoms, not mentioned above, that you should specifically enquire about to help rule out sinister medical causes for her fatigue. List TWO.",
  },
  {
    id: "3",
    heading: "Question 3",
    description:
      "List other symptoms, not mentioned above, that you should specifically enquire about to help rule out sinister medical causes for her fatigue. List TWO.",
  },
  {
    id: "4",
    heading: "Question 4",
    description:
      "List other symptoms, not mentioned above, that you should specifically enquire about to help rule out sinister medical causes for her fatigue. List TWO.",
  },
  {
    id: "5",
    heading: "Question 5",
    description:
      "List other symptoms, not mentioned above, that you should specifically enquire about to help rule out sinister medical causes for her fatigue. List TWO.",
  },
  {
    id: "6",
    heading: "Question 6",
    description:
      "List other symptoms, not mentioned above, that you should specifically enquire about to help rule out sinister medical causes for her fatigue. List TWO.",
  },
  {
    id: "7",
    heading: "Question 7",
    description:
      "List other symptoms, not mentioned above, that you should specifically enquire about to help rule out sinister medical causes for her fatigue. List TWO.",
  },
  {
    id: "8",
    heading: "Question 8",
    description:
      "List other symptoms, not mentioned above, that you should specifically enquire about to help rule out sinister medical causes for her fatigue. List TWO.",
  },
];
