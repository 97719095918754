export const capitalizeTitle = (title) => {
    return title.replace(/\b\w/g, (char) => char.toUpperCase());
};

// export const formatCardNumber = (number) => {
//     const groups = number.match(/.{1,4}/g); 
//     return groups ? groups.join(' ') : '';
//   };
export const formatCardNumber = (value) => {
    return value
      .replace(/\s/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
};


export const detectCardType = (number) => {
    if (/^4[0-9]{0,15}$/.test(number)) {
      return 'Visa';
    } else if (/^5[1-5][0-9]{0,14}$/.test(number)) {
      return 'MasterCard';
    } else if (/^3[47][0-9]{0,13}$/.test(number)) {
      return 'American Express';
    } else if (/^6(?:011|5[0-9]{2})[0-9]{0,12}$/.test(number)) {
      return 'Discover';
    } else {
      return ''; 
    }
  };