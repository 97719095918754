import React from "react";

const CustomButton = ({
  text,
  width = "180px",
  height = "48px",
  type = "button",
  borderRadius = "8px",
  isDeleteModal = false,
  loading = false,
  disabled = false,
  onClick,
  className = "", 
}) => {
  const computedWidth =
    isDeleteModal || window.location.pathname === "/profile" ||
    window.location.pathname === "/contactus" ||
    window.location.pathname === "/aboutus"
      ? width
      : "300px";

  const textColor = className.includes("bg-white") ? "text-black" : "text-white";

  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
      className={`relative flex items-center justify-center ${textColor} text-[24px] font-medium transition duration-200 ease-in-out
        bg-[#060531] 
        rounded-[${borderRadius}] 
        w-[${computedWidth}] 
        h-[${height}] 
        shadow-lg 
        disabled:opacity-50
        ${loading ? "cursor-not-allowed" : "hover:opacity-90"} 
        ${className} // Add extra classes here
      `}
      style={{ width, height, borderRadius }}
    >
      {loading ? (
        <div className="absolute">
          <svg
            className="animate-spin h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              fill="none"
              strokeWidth="4"
              stroke="currentColor"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0z"
            />
          </svg>
        </div>
      ) : (
        text
      )}
    </button>
  );
};

export default CustomButton;
