import React from "react";
import { IMAGES } from "../utils/Images";

const Splash = () => {
  return (
    <div className="w-full h-screen bg-splashBg bg-no-repeat bg-cover flex justify-center items-center flex-col">
      <div className="animated-element flex flex-col justify-center items-center">
        <img
          src={IMAGES.LOGO}
          className="md:h-[180px] md:w-[200px]  h-[150px] w-[150px] object-contain my-5"
          alt="Logo"
        />
        <p className="lg:text-[60px] text-[45px] font-bold text-center webName ">
          SAMPTRACK
        </p>
        <p className="lg:text-[30px] text-[22px] font-medium text-center text-white  ">
          TRACK, TACKLE, TRIUMPH
        </p>
      </div>
    </div>
  );
};

export default Splash;
