import React, { useState, useEffect } from "react";
import Container from "../../Components/Container";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../api/axios.tsx";
import { useDispatch, useSelector } from "react-redux";
import {
  setCasesForTest,
  setQuestionLimitForTest,
  setUserData,
} from "../../redux/slices/AuthSlice.js";
import { Toast } from "../../Components/toast.jsx";
import {
  deleteCard,
  getCards,
  getSubscriptionPlans,
  getSubscriptionPlansById,
  getUserSubscriptionStatus,
  subscribtionPayment,
} from "../../actions/getContent.jsx";
import CustomModal from "../../Components/CustomModal.jsx";
import PlanCard from "../../Components/CustomCard.jsx";
import { formatCardNumber } from "../../utils/helper.js";
import Swal from "sweetalert2";
import { MdDelete } from "react-icons/md";
import ButtonWithLoading from "../../Components/ButtonWithLoading.tsx";

const Home = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.Auth?.user);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [cases, setCases] = useState(null);
  const [limitText, setLimitText] = useState(40);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [subscribeCard, setSubscribeCard] = useState([]);
  const [userSubscription, setUserSubscription] = useState(null);
  const [checkoutOpen, setCheckoutOpen] = React.useState(false);
  const [cards, setCards] = React.useState([]);
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [cardNumber, setCardNumber] = React.useState("");
  const [expDate, setExpDate] = React.useState("");
  const [securityCode, setSecurityCode] = React.useState("");
  const [formData, setFormData] = React.useState({
    exp_month: "",
    exp_year: "",
  });
  const [cardType, setCardType] = React.useState("");
  const [error, setError] = React.useState("");
  const [cardError, setCardError] = React.useState("");
  const [planDetail, setPlanDetail] = React.useState(null);

  const featuresList = Array(5).fill("Lorem ipsum");

  React.useEffect(() => {
    const getAllCards = async () => {
      try {
        const res = await getCards();
        setCards(res?.data?.cards || []);
      } catch (error) {}
    };
    getAllCards();
  }, []);

  const handleCheckoutOpen = () => setCheckoutOpen(true);
  const handleCheckoutClose = () => setCheckoutOpen(false);

  const detectCardType = (number) => {
    if (/^4[0-9]{0,15}$/.test(number)) {
      setCardType("Visa");
    } else if (/^5[1-5][0-9]{0,14}$/.test(number)) {
      setCardType("MasterCard");
    } else if (/^3[47][0-9]{0,13}$/.test(number)) {
      setCardType("American Express");
    } else if (/^6(?:011|5[0-9]{2})[0-9]{0,12}$/.test(number)) {
      setCardType("Discover");
    } else {
      setCardType("");
    }
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 16);

    const formattedValue = formatCardNumber(value);
    setCardNumber(formattedValue);
    detectCardType(value);
  };

  const handleExpirationDateChange = (e) => {
    const cleanedValue = e.target.value.replace(/\D/g, "");
    const value = cleanedValue
      .replace(/(.{2})/, "$1/")
      .trim()
      .slice(0, 5);

    if (value.length === 2 && !value.includes("/")) {
      setExpDate(`${value}/`);
    } else {
      setExpDate(value);
    }

    if (value.length === 5) {
      const month = parseInt(value.slice(0, 2), 10);
      const year = parseInt(value.slice(3), 10);
      const currentYear = new Date().getFullYear() % 100;
      const currentMonth = new Date().getMonth() + 1;

      setFormData({
        ...formData,
        exp_month: value.slice(0, 2),
        exp_year: value.slice(3),
      });
      if (
        month < 1 ||
        month > 12 ||
        year < currentYear ||
        (year === currentYear && month < currentMonth)
      ) {
        setError("Invalid expiration date. Please check your month and year.");
      } else {
        setError("");
      }
    }
  };

  const handleSecurityCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
    setSecurityCode(value);
  };

  const handleCardSelect = (id) => {
    setSelectedCard(id);
  };

  const updateItemsPerPage = () => {
    if (cases?.data?.total > 18) {
      if (window.innerWidth < 768) {
        setItemsPerPage(18);
      } else if (window.innerWidth < 1200) {
        setItemsPerPage(24);
      } else {
        setItemsPerPage(48);
      }
    } else {
      setItemsPerPage(cases?.data?.total);
    }
  };

  async function getAllItems() {
    try {
      const response = await get({
        url: "/case-objectives",
      });
      if (response?.data != null) {
        setCases(response);
      }
    } catch (error) {
      Toast({ message: error?.message, isError: true });
    }
  }
  useEffect(() => {
    updateItemsPerPage();
  }, [cases]);

  useEffect(() => {
    getAllItems();
    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);
  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      const response = await getSubscriptionPlans();
      if (response?.data) {
        setSubscribeCard(response.data);
      }
    };

    fetchSubscriptionPlans();
  }, []);

  useEffect(() => {
    const fetchUserSubscriptionStatus = async () => {
      const status = await getUserSubscriptionStatus();
      setUserSubscription(status);
    };

    fetchUserSubscriptionStatus();
  }, []);
  const totalPages = Math.ceil(cases?.data?.total / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = cases?.data?.data?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSelectedCheckboxes([]);
  };
  const dispatch = useDispatch();
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedCheckboxes(currentItems?.map((item, index) => item?.id));
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleCheckboxChange = (index) => {
    if (user?.subscribed == 0) {
      setIsModalOpen(true);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.includes(index)
          ? prevSelected.filter((item) => item !== index)
          : [...prevSelected, index]
      );
    }
  };

  const handleSubmit = () => {
    if (selectedCheckboxes?.length) {
      dispatch(setCasesForTest(selectedCheckboxes));
      dispatch(setQuestionLimitForTest(limitText));
      navigate("/test", { state: { showModal: true } });
    } else {
      Toast({ message: "Please select case to proceed", isError: true });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getVisiblePageNumbers = () => {
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage === 1) {
        startPage = 1;
        endPage = 3;
      } else if (currentPage === totalPages) {
        startPage = totalPages - 2;
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  const handleAddCard = async (e) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to create a card?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#050531",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, subscribe!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      setIsLoading(true);
      const paymentData = {
        card_number: cardNumber,
        ...formData,
        cvc: securityCode,
      };
      try {
        const response = await post({
          url: "/user/stripe-token/create-token",
          data: paymentData,
          includeToken: true,
        });
        await post({
          url: "/user/cards/add",
          data: { stripe_token: response?.data?.id },
          includeToken: true,
        });
        closeModal();
        Swal.fire("Success!", "Subscription created successfully!", "success");
        const updatedCards = await getCards();
        setCards(updatedCards?.data?.cards || []);
        setCardNumber("");
        setExpDate("");
        setSecurityCode("");
        setFormData({
          exp_month: "",
          exp_year: "",
        });
        setCardType("");
      } catch (error) {
        Swal.fire("Error!", "Failed to create subscription.", "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubscriptionPlan = async (id) => {
    try {
      const res = await getSubscriptionPlans(id);
      console.log("Res -->", res);
    } catch (err) {
      console.error("Error -> ", err);
    }
  };

  const handleGetPlan = async (planId) => {
    const res = await getSubscriptionPlansById(planId);
    setPlanDetail(res?.data);
  };

  const handleSubscriptionApi = async (id, e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await subscribtionPayment(id);
      dispatch(setUserData(res?.data));
      Swal.fire("Success!", "Subscription successful!", "success");
    } catch (error) {
      Swal.fire("Error!", "Subscription failed.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCard = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this card?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#050531",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      try {
        const res = await deleteCard(id);
        if (res?.code === 200) {
          setCards((prevCards) =>
            prevCards.filter((card) => card?.stripe_source_id !== id)
          );
          Swal.fire("Deleted!", "Your card has been deleted.", "success");
        } else {
          Swal.fire("Error!", "Failed to delete the card.", "error");
        }
      } catch (error) {
        Swal.fire("Error!", "An error occurred.", "error");
      }
    }
  };

  const onSubmit = (e) => {
    if (selectedCard) {
      handleSubscriptionApi(planDetail?.id, e);
    } else {
      handleAddCard(e);
    }
  };

  const visiblePageNumbers = getVisiblePageNumbers();

  return (
    <Container mainStyle="h-full ">
      <div className="  mx-auto py-2 md:px-7 px-2 overflow-hidden h-full">
        <div className="flex items-center justify-between md:my-4 mb-2 ">
          <div className="flex items-center sm:-ml-2">
            <input
              type="checkbox"
              id="select-all"
              onChange={handleSelectAll}
              checked={
                selectedCheckboxes?.length === currentItems?.length &&
                currentItems?.length > 0
              }
            />
            <label
              htmlFor="select-all"
              className="ml-2 font-semibold md:text-[16px] text-[12px]"
            >
              Subjects
            </label>
          </div>
          <div>
            <Button
              onPress={handleSubmit}
              divstyle="rounded-full text-white bg-[#060531] font-medium lg:px-5 md:px-5 px-3 py-[6px] md:text-[12px] text-[8px]"
              btnname="Start Test"
              type="submit"
            />
          </div>
        </div>
        <div className="p-3 border-[1px] border-[#00000029] rounded-lg ">
          <div className="grid grid-flow-col lg:grid-rows-12 lg:grid-cols-4 md:grid-rows-6 md:grid-cols-2 grid-rows-6 md:gap-4 gap-2">
            {currentItems?.map((item, index) => (
              <div key={index} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id={`checkbox-${item?.id}`}
                  checked={selectedCheckboxes?.includes(item?.id)}
                  onChange={() => handleCheckboxChange(item?.id)}
                />
                <label
                  className="text-[#272727] font-normal md:text-[15px] text-[10px]"
                  htmlFor={`checkbox-${item?.id}`}
                >
                  {item?.name}
                </label>
              </div>
            ))}
          </div>


          <div className="flex items-center md:flex-row flex-col justify-between mt-5 gap-5">
            <div className="flex justify-between md:w-[60%] w-[100%] items-center">
              <div>
                <p className="font-normal lg:text-[16px] md:text-[13px] text-[9px] text-black">
                  Showing {startIndex + 1} to{" "}
                  {Math.min(
                    startIndex + currentItems?.length,
                    cases?.data?.total
                  )}{" "}
                  of {cases?.data?.total} Entries
                </p>

                {/* <p className="font-normal lg:text-[16px] md:text-[13px] text-[9px] text-black">
                  Showing {startIndex + 1} to{" "}
                  {startIndex + currentItems?.length} of {cases?.data?.total}{" "}
                  Entries
                </p> */}
              </div>

              <div className="flex gap-2 items-center">
                <p className="font-normal lg:text-[16px] md:text-[13px] text-[9px] text-black">
                  No. of Questions
                </p>
                <div className="border-[1px] border-black md:px-3 md:py-1 px-1 py-1">
                  <input
                    type="text"
                    maxLength={2}
                    style={{
                      width: 20,
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (
                        /^\d{0,2}$/.test(value) &&
                        (value === "" || parseInt(value, 10) <= 80)
                      ) {
                        setLimitText(value);
                      }
                    }}
                    value={limitText}
                  />
                  {/* <p className="text-[10px] md:text-[10px] text-[8px] text-black">
                    40
                  </p> */}
                </div>
                <p className="text-xs text-[#9F9F9F] font-normal">
                  Max allowed per block
                </p>
                <div className="border-[1px] bg-[#E2E2E2] px-[2px]">
                  <p className="text-[8px] text-[#646464]">80</p>
                </div>
              </div>
            </div>

            <div className="flex bg-white border-[#E2E2E2] border-[1px] rounded-md py-[2px] px-[2px] shadow-md">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="md:px-3 px-1 py-1 text-[#2172DC] lg:text-[16px] md:text-[13px] text-[10px]"
              >
                Previous
              </button>

              <div className="flex">
                {visiblePageNumbers?.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`px-3 py-1 md:text-[14px] text-[10px] ${
                      currentPage === pageNumber
                        ? "bg-[#060531] text-white rounded"
                        : "text-[#000000]"
                    }`}
                  >
                    {pageNumber}
                  </button>
                ))}
              </div>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="md:px-3 md:py-1 px-2 py-2 text-[#2172DC] lg:text-[16px] md:text-[13px] text-[10px]"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      {user?.subscribed === 0 && (
        <>
          <CustomModal
            open={isModalOpen}
            onClose={closeModal}
            title="Premium plan for users"
            closeIcon={true}
          >
            <div className="flex flex-col md:flex-row gap-6 p-6">
              {subscribeCard?.map((val) => {
                return (
                  <PlanCard
                    id={val?.id}
                    title={val?.title}
                    price={val?.amount}
                    disabled={val?.subscribed === 1 ? true : false}
                    buttonText={
                      val?.subscribed === 1 ? "Subscribed" : "Get Plan"
                    }
                    features={featuresList}
                    titleColor={
                      val?.type === "monthly"
                        ? "text-green-600"
                        : "text-purple-600"
                    }
                    circleColor={
                      val?.type === "monthly"
                        ? "bg-gradient-to-br from-green-400 to-green-600"
                        : "bg-gradient-to-br from-purple-400 to-purple-600"
                    }
                    buttonColor={
                      val?.type === "monthly"
                        ? "border border-gray-900 text-gray-900"
                        : "bg-gray-900 text-white"
                    }
                    onButtonClick={() => {
                      handleCheckoutOpen();
                      handleGetPlan(val?.id);
                    }}
                  />
                );
              })}
            </div>
          </CustomModal>
          <CustomModal
            open={checkoutOpen}
            onClose={handleCheckoutClose}
            title=""
            closeIcon={false}
          >
            <form
              onSubmit={onSubmit}
              className="max-w-md mx-auto p-2 space-y-6"
            >
              <div className="border border-gray-300 p-6 rounded-md">
                <p className="text-[#000000]">Your subscription</p>
                <div className="flex justify-between mt-2">
                  <span className="font-semibold text-[#858585]">
                    {planDetail?.title} Plan
                  </span>
                  <span className="text-[#858585]">
                    ${planDetail?.amount} Billed {planDetail?.type}
                  </span>
                </div>
                <hr className="my-4" />
                <div className="flex justify-between font-semibold">
                  <span className="text-[#000000]">Total</span>
                  <span className="text-[#000000]">$15.00</span>
                </div>
              </div>
              <div className="flex flex-col space-x-2">
                {cards?.length > 0 ? (
                  <div className="flex flex-col ">
                    {cards?.map((card) => (
                      <div
                        key={card.id}
                        className={`border-dashed border-2 p-4 rounded-md flex justify-between items-center mb-3 cursor-pointer ${
                          selectedCard === card.id
                            ? "border-blue-500"
                            : "border-gray-300"
                        } relative`}
                        onClick={() => handleCardSelect(card.id)}
                      >
                        <button
                          className="absolute top-[-12px] right-[-2px] text-red-500 w-5"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteCard(card?.stripe_source_id);
                          }}
                          type="button"
                        >
                          <MdDelete size={24} />
                        </button>

                        <div className="flex items-center">
                          <img
                            src={
                              card.card_brand === "Visa"
                                ? "/assets/CardImage/icons8-visa-96.png"
                                : card.card_brand === "MasterCard"
                                ? "/assets/CardImage/icons8-mastercard-96.png"
                                : card.card_brand === "Discover"
                                ? "/assets/CardImage/icons8-discover-card-96.png"
                                : card.card_brand === "American Express"
                                ? "assets/CardImage/icons8-american-express-96.png"
                                : ""
                            }
                            alt={card.card_brand}
                            className="h-10 w-10 object-contain"
                          />
                          <div className="h-10 border-l-2 border-[#8292BB] mx-3"></div>
                          <div className="ml-3">
                            <p className="text-sm font-semibold text-black">
                              {card.card_brand}
                            </p>
                            <p className="text-sm text-gray-500">
                              {card.number}
                            </p>
                          </div>
                        </div>

                        <div className="h-6 w-6 border-2 rounded-full flex items-center justify-center">
                          {selectedCard === card.id && (
                            <div className="h-3 w-3 bg-[#1D274B] rounded-full"></div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="text-center cursor-pointer">
                <p className="text-[#858585]">Or Add Another Card</p>
              </div>
              <div className="p-3 border border-gray-300 rounded-md flex justify-between items-center w-full">
                <div className="flex items-center w-full">
                  <input
                    className="w-full"
                    placeholder="Card Number"
                    value={cardNumber}
                    onChange={handleCardNumberChange}
                    style={{
                      fontSize: "16px",
                      color: "#000",
                      letterSpacing: "0.025em",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    }}
                  />
                  {cardError && <p className="text-red-500">{cardError}</p>}
                  <div className="flex space-x-2 ml-2">
                    {cardType ? (
                      <p>{cardType}</p>
                    ) : (
                      <img
                        src={
                          cardType === "Visa"
                            ? "/assets/CardImage/icons8-visa-96.png"
                            : cardType === "MasterCard"
                            ? "/assets/CardImage/icons8-mastercard-96.png"
                            : cardType === "Discover"
                            ? "/assets/CardImage/icons8-discover-card-96.png"
                            : cardType === "American Express"
                            ? "assets/CardImage/icons8-american-express-96.png"
                            : "/assets/card.png"
                        }
                        alt="Visa"
                        className="h-10 w-18 object-contain"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="flex space-x-2">
                <div className="w-1/2">
                  <div className="p-5 border border-gray-300 rounded-md">
                    <input
                      className="w-full"
                      placeholder="Expiration Date (MM/YY)"
                      value={expDate}
                      onChange={handleExpirationDateChange}
                      style={{
                        fontSize: "16px",
                        color: "#000",
                        letterSpacing: "0.025em",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      }}
                    />
                    {error && <p className="text-red-500">{error}</p>}
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="p-5 border border-gray-300 rounded-md">
                    <input
                      className="w-full"
                      placeholder="Security Code"
                      value={securityCode}
                      onChange={handleSecurityCodeChange}
                      style={{
                        fontSize: "16px",
                        color: "#000",
                        letterSpacing: "0.025em",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <ButtonWithLoading
                type="submit"
                isLoading={isLoading}
                buttonText={selectedCard ? "Subscribe" : "Add Card"}
                className="custom-button-class"
              />
            </form>
          </CustomModal>
        </>
      )}
    </Container>
  );
};

export default Home;
