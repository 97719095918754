"use client";

import React from "react";

import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import Container from "../../Components/Container";
import CardHeader from "../../Components/CardHeader";
import TextFields from "../../Components/TextField";
import CustomButton from "../../Components/CustomButton";
import { Toast } from "../../Components/toast";
import { ChangePasswordSchema } from "../../utils/schema";
import { post } from "../../api/axios.tsx";

const ChangePassword = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);

  const handleClicked = async (values) => {
    try {
      setIsLoading(true);
      const response = await post({
        url: "/user/change-password",
        includeToken: true,
        data: values,
      });
      Toast({ message: response?.messages[0], isError: false });
    } catch (error) {
      Toast({
        message: error?.response?.data?.error?.messages?.[0],
        isError: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      current_password: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      await handleClicked(values);
      resetForm();
    },
  });

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  return (
    <Container>
      <div
        className={`w-full h-full p-3 sm:p-6 md:px-12 md:p-8 bg-[#FFFFFF]`}
        // style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
      >
        <CardHeader header="Change Password" onClick={() => navigate(-1)} />

        <div className="p-8 bg-[#FFFFFF] shadow-md rounded-b-lg">
          <div className="mt-10 space-y-10">
            <TextFields
              placeholder="Current Password"
              type="password"
              name="current_password"
              icon={"/assets/Group 81768.svg"}
              width="100%"
              height="50px"
              value={values?.current_password}
              onChange={handleChange}
            />
            {errors.current_password && touched.current_password && (
              <span style={{ color: "red" }}>
                {errors.current_password}
              </span>
            )}
            <TextFields
              placeholder="New Password"
              type="password"
              name="password"
              icon={"/assets/Group 81768.svg"}
              width="100%"
              height="50px"
              value={values?.password}
              onChange={handleChange}
            />
            {errors.password && touched.password && (
              <span style={{ color: "red" }}>{errors.password}</span>
            )}
            <TextFields
              placeholder="Confirm Password"
              type="password"
              name="confirm_password"
              icon={"/assets/Group 81768.svg"}
              width="100%"
              height="50px"
              value={values?.confirm_password}
              onChange={handleChange}
            />
            {errors.confirm_password && touched.confirm_password && (
              <span style={{ color: "red" }}>
                {errors.confirm_password}
              </span>
            )}
          </div>

          <div
            className="flex justify-center"
            style={{ marginTop: "2rem", marginBottom: "2rem" }}
          >
            <CustomButton
              text="Update"
              onClick={handleSubmit}
              width="160px"
              height="60px"
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ChangePassword;
