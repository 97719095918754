import { Icon } from "@iconify/react";

export const CustomIcon = ({
  name,
  vFlip,
  hFlip = false,
  fontSize = 26,
  className,
  onClick,
  style,
}) => {
  return (
    <Icon
      icon={name}
      className={`text-white cursor-pointer transition-all duration-1000  ${className}`}
      fontSize={fontSize}
      hFlip={hFlip}
      style={style}
      vFlip={vFlip}
      onClick={onClick}
    />
  );
};
