import React, { useState } from "react";
import Inputfield from "../../../Components/Inputfield";
import Button from "../../../Components/Button";
import { IMAGES } from "../../../utils/Images";
import PasswordInput from "../../../Components/PasswordInput";
import { useNavigate } from "react-router-dom";
import { LoginUser } from "../../../redux/slices/AuthSlice";
import { useDispatch } from "react-redux";
import { Signupvalidationschema } from "../../../utils/schema";
import { useFormik } from "formik";
import { post } from "../../../api/axios.tsx";
import { Toast } from "../../../Components/toast.jsx";

const Register = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await post({
        url: "/register",
        includeToken: false,
        data: values,
      });

      if (response?.data != null) {
        Toast({ message: response?.messages?.[0] });
        console.log(response?.messages?.[0]);
        dispatch(LoginUser(response?.data));
        navigate("/");
      }

      console.log("response", response);
    } catch (error) {
      console.log("Errror -> ", error?.message);
      // const respon = error?.response?.data?.error?.messages
      // const messages = Object?.keys(respon)
      // Toast({ message: respon[messages[0]][0], isError: true });
    } finally {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      test_name: "",
      language: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Signupvalidationschema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <form
      className="w-full md:mt-12 md:mb-2 mt-5 mb-10 "
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <Inputfield
        innerDiv="mt-[30px] bg-white rounded-full w-full  px-5 bg-transparent"
        inputStyle="w-full px-3 py-3 "
        type="text"
        placeholder="Enter your First Name"
        name="first_name"
        htmlFor="first_name"
        id="first_name"
        autoComplete="off"
        leadingImage={IMAGES.AUTH_USER}
        imageStyle="w-[30px] h-[30px] -ml-3 mr-2"
        onChange={formik.handleChange}
        values={formik.values.first_name}
        errors={formik.errors.first_name}
        touched={formik.touched.first_name}
      />

      <Inputfield
        innerDiv="mt-[30px] bg-white rounded-full w-full  px-5   bg-transparent"
        inputStyle="w-full px-3 py-3 "
        type="text"
        placeholder="Enter your Last Name"
        name="last_name"
        htmlFor="last_name"
        id="last_name"
        autoComplete="off"
        leadingImage={IMAGES.AUTH_USER}
        imageStyle="w-[30px] h-[30px] -ml-3 mr-2"
        onChange={formik.handleChange}
        values={formik.values.last_name}
        errors={formik.errors.last_name}
        touched={formik.touched.last_name}
      />

      <Inputfield
        innerDiv="mt-[30px] bg-white rounded-full w-full  px-5   bg-transparent"
        inputStyle="w-full px-3 py-3 "
        type="text"
        placeholder="Enter your Test Name"
        name="test_name"
        htmlFor="test_name"
        id="test_name"
        autoComplete="off"
        leadingImage={IMAGES.AUTH_TEST}
        imageStyle="w-[30px] h-[30px] -ml-3 mr-2"
        onChange={formik.handleChange}
        values={formik.values.test_name}
        errors={formik.errors.test_name}
        touched={formik.touched.test_name}
      />

      <Inputfield
        innerDiv="mt-[30px] bg-white rounded-full w-full  px-5   bg-transparent"
        inputStyle="w-full px-3 py-3 "
        type="text"
        placeholder="Enter your Language"
        name="language"
        htmlFor="language"
        id="language"
        autoComplete="off"
        leadingImage={IMAGES.AUTH_LANGUAGE}
        imageStyle="w-[30px] h-[30px] -ml-3 mr-2"
        onChange={formik.handleChange}
        values={formik.values.language}
        errors={formik.errors.language}
        touched={formik.touched.language}
      />

      <Inputfield
        innerDiv="mt-[30px] bg-white rounded-full w-full  px-5   bg-transparent"
        inputStyle="w-full px-3 py-3  "
        type="email"
        placeholder="Enter your Email"
        name="email"
        htmlFor="email"
        id="email"
        leadingImage={IMAGES.EMAIL}
        imageStyle="w-[30px] h-[30px] -ml-3 mr-2"
        onChange={formik.handleChange}
        values={formik.values.email}
        errors={formik.errors.email}
        touched={formik.touched.email}
      />

      <PasswordInput
        innerdiv="mt-[30px] bg-white rounded-full w-full px-5 bg-transparent"
        inputStyle="w-full px-3 py-3  "
        name="password"
        htmlFor="password"
        id="password"
        autoComplete="off"
        placeholder="Enter Password"
        leadingImage={IMAGES.AUTH_KEY}
        imageStyle="w-[30px] h-[30px] -ml-3 mr-2"
        onChange={formik.handleChange}
        value={formik.values.password}
        errors={formik.errors.password}
        touched={formik.touched.password}
      />

      <PasswordInput
        innerdiv="mt-[30px] bg-white rounded-full w-full px-5 bg-transparent"
        inputStyle="w-full px-3 py-3  "
        name="password_confirmation"
        placeholder="Enter Confirm Password"
        leadingImage={IMAGES.AUTH_KEY}
        autoComplete="off"
        imageStyle="w-[30px] h-[30px] -ml-3 mr-2"
        onChange={formik.handleChange}
        value={formik.values.password_confirmation}
        errors={formik.errors.password_confirmation}
        touched={formik.touched.password_confirmation}
      />

      <div className="flex justify-between lg:mt-[35px] mt-[20px] focus:outline-none items-center">
        <div class="flex items-center focus:outline-none">
          <label class="flex items-center cursor-pointer">
            <input
              type="checkbox"
              class="hidden peer"
              value={checked}
              onChange={() => setChecked((prev) => !prev)}
            />
            <div
              class=" md:w-[30px] md:h-[30px] w-[15px]  h-[15px] rounded-full  focus:outline-none
             flex items-center justify-center"
            >
              {checked ? (
                <img
                  src={IMAGES.CHECKED}
                  className="md:w-[30px] md:h-[30px] w-[15px]  h-[15px]"
                />
              ) : (
                <div className="  rounded-full bg-white md:w-[30px] md:h-[30px] w-[15px]  h-[15px]"></div>
              )}
            </div>
            <span class="ms-2 text-sm font-normal text-white">Remember</span>
          </label>
        </div>

        <div className="cursor-pointer">
          <a
            onClick={() => navigate("/forgotPassword")}
            className=" text-white  font-normal text-sm "
          >
            Forgot password?
          </a>
        </div>
      </div>

      <div className=" flex justify-center ">
        <Button
          divstyle="  w-[50%]  md:mt-[30px] mt-[30px]  rounded-full text-[#060531] bg-white font-medium lg:py-3 py-1 text-[20px]"
          btnname="Sign Up"
          type="submit"
          loading={isLoading}
        />
      </div>
    </form>
  );
};

export default Register;
