import React from "react";

import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import Swal from "sweetalert2";

import Container from "../../Components/Container";
import CardHeader from "../../Components/CardHeader";
import SettingCard from "../../Components/SettingCard";
import DeleteModal from "../../Components/DeleteModal";
import CustomButton from "../../Components/CustomButton";
import CustomModal from "../../Components/CustomModal";
import PlanCard from "../../Components/CustomCard";

import { get, post, remove } from "../../api/axios.tsx";
import { Toast } from "../../Components/toast";
import { LogoutUser, setUserData } from "../../redux/slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultCardSelect,
  deleteCard,
  getCards,
  getSubscriptionPlans,
  getSubscriptionPlansById,
  getUserSubscriptionStatus,
  subscribtionPayment,
} from "../../actions/getContent.jsx";
import { formatCardNumber } from "../../utils/helper.js";
import { MdDelete } from "react-icons/md";
import ButtonWithLoading from "../../Components/ButtonWithLoading.tsx";

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.Auth?.user);

  const [subscribeCard, setSubscribeCard] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [checkoutOpen, setCheckoutOpen] = React.useState(false);
  const [cards, setCards] = React.useState([]);
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [userSubscription, setUserSubscription] = React.useState(null);
  const [cardNumber, setCardNumber] = React.useState("");
  const [expDate, setExpDate] = React.useState("");
  const [securityCode, setSecurityCode] = React.useState("");
  const [formData, setFormData] = React.useState({
    exp_month: "",
    exp_year: "",
  });
  const [cardType, setCardType] = React.useState("");
  const [error, setError] = React.useState("");
  const [cardError, setCardError] = React.useState("");
  const [planDetail, setPlanDetail] = React.useState(null);
  const [defaultCard, setDefaultCard] = React.useState(null);

  const featuresList = Array(5).fill("Lorem ipsum");

  console.log("defaultCard --> ", defaultCard);

  React.useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      const response = await getSubscriptionPlans();
      if (response?.data) {
        setSubscribeCard(response?.data);
      }
    };

    fetchSubscriptionPlans();
  }, []);

  React.useEffect(() => {
    const fetchUserSubscriptionStatus = async () => {
      const status = await getUserSubscriptionStatus();
      setUserSubscription(status);
    };

    fetchUserSubscriptionStatus();
  }, []);

  React.useEffect(() => {
    const getAllCards = async () => {
      try {
        const res = await getCards();
        setCards(res?.data?.cards || []);
        const defaultCard = res?.data?.cards?.find(card => card?.default_card === 1);
        if (defaultCard) {
          setDefaultCard(defaultCard?.id); 
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAllCards();
  }, []);

  // Delete Modal
  const handleIsOpen = () => setIsOpen(true);
  const handleIsClose = () => setIsOpen(false);
  // SubscritipnModal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // Checkout
  const handleCheckoutOpen = () => setCheckoutOpen(true);
  const handleCheckoutClose = () => setCheckoutOpen(false);

  const handleLogout = () => navigate("/login");

  const handleClicked = () => navigate("/");

  const detectCardType = (number) => {
    if (/^4[0-9]{0,15}$/.test(number)) {
      setCardType("Visa");
    } else if (/^5[1-5][0-9]{0,14}$/.test(number)) {
      setCardType("MasterCard");
    } else if (/^3[47][0-9]{0,13}$/.test(number)) {
      setCardType("American Express");
    } else if (/^6(?:011|5[0-9]{2})[0-9]{0,12}$/.test(number)) {
      setCardType("Discover");
    } else {
      setCardType("");
    }
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 16);

    const formattedValue = formatCardNumber(value);
    setCardNumber(formattedValue);
    detectCardType(value);
  };

  const handleExpirationDateChange = (e) => {
    const cleanedValue = e.target.value.replace(/\D/g, "");
    const value = cleanedValue
      .replace(/(.{2})/, "$1/")
      .trim()
      .slice(0, 5);

    if (value.length === 2 && !value.includes("/")) {
      setExpDate(`${value}/`);
    } else {
      setExpDate(value);
    }

    if (value.length === 5) {
      const month = parseInt(value.slice(0, 2), 10);
      const year = parseInt(value.slice(3), 10);
      const currentYear = new Date().getFullYear() % 100;
      const currentMonth = new Date().getMonth() + 1;

      setFormData({
        ...formData,
        exp_month: value.slice(0, 2),
        exp_year: value.slice(3),
      });
      if (
        month < 1 ||
        month > 12 ||
        year < currentYear ||
        (year === currentYear && month < currentMonth)
      ) {
        setError("Invalid expiration date. Please check your month and year.");
      } else {
        setError("");
      }
    }
  };

  const handleSecurityCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
    setSecurityCode(value);
  };

  const handleCardSelect = async (id) => {
    setSelectedCard(id);
    const selectedCard = cards.find(card => card.id === id);
  
    if (selectedCard && selectedCard?.stripe_source_id) {
      await defaultCardSelect(selectedCard?.stripe_source_id);
      setDefaultCard(selectedCard?.id);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await remove({
        url: "/user/delete-account",
        includeToken: true,
      });
      Toast({ message: response?.messages[0], isError: false });
      dispatch(LogoutUser());

      navigate("/login");
    } catch (error) {
      Toast({ message: error?.messages?.[0], isError: true });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to create a token and subscribe?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, subscribe!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      setIsLoading(true);
      const paymentData = {
        card_number: cardNumber,
        ...formData,
        cvc: securityCode,
      };
      try {
        const response = await post({
          url: "/user/stripe-token/create-token",
          data: paymentData,
          includeToken: true,
        });
        await post({
          url: "/user/cards/add",
          data: { stripe_token: response?.data?.id },
          includeToken: true,
        });
        handleClose();
        Swal.fire("Success!", "Subscription created successfully!", "success");
        const updatedCards = await getCards();
        setCards(updatedCards?.data?.cards || []);
        // Reset form fields
        setCardNumber("");
        setExpDate("");
        setSecurityCode("");
        setFormData({
          exp_month: "",
          exp_year: "",
        });
        setCardType("");
      } catch (error) {
        Swal.fire("Error!", "Failed to create subscription.", "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleGetPlan = async (planId) => {
    const res = await getSubscriptionPlansById(planId);
    setPlanDetail(res?.data);
  };

  const handleSubscriptionApi = async (id, e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await subscribtionPayment(id);
      dispatch(setUserData(res?.data));
      Swal.fire("Success!", "Subscription successful!", "success");
    } catch (error) {
      Swal.fire("Error!", "Subscription failed.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCard = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this card?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      onfirmButtonColor: "#050531",
      cancelButtonColor: "#d33",
    });

    if (result.isConfirmed) {
      try {
        const res = await deleteCard(id);
        if (res?.code === 200) {
          setCards((prevCards) =>
            prevCards.filter((card) => card?.stripe_source_id !== id)
          );
          Swal.fire("Deleted!", "Your card has been deleted.", "success");
        } else {
          Swal.fire("Error!", "Failed to delete the card.", "error");
        }
      } catch (error) {
        Swal.fire("Error!", "An error occurred.", "error");
      }
    }
  };

  const onSubmit = (e) => {
    if (selectedCard) {
      handleSubscriptionApi(planDetail?.id, e);
    } else {
      handleSubmit(e);
    }
  };

  return (
    <Container>
      <div
        className={`w-full h-full p-3 sm:p-6 md:px-12 md:p-8 bg-[#FFFFFF]`}
        style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
      >
        <CardHeader header="Settings" onClick={handleClicked} />

        <SettingCard
          header="Basic Settings"
          items={[
            {
              title: "Terms and Conditions",
              icon: <AiOutlineArrowRight size={20} color="#4D4D4D" />,
              path: "/termsconditions",
            },
            {
              title: "Privacy Policy",
              icon: <AiOutlineArrowRight size={20} color="#4D4D4D" />,
              path: "/privacypolicy",
            },
            {
              title: "About Us",
              icon: <AiOutlineArrowRight size={20} color="#4D4D4D" />,
              path: "/aboutus",
            },
            {
              title: "Contact Us",
              icon: <AiOutlineArrowRight size={20} color="#4D4D4D" />,
              path: "/contactus",
            },
            {
              title: "Subscription",
              icon: <AiOutlineArrowRight size={20} color="#4D4D4D" />,
              onButtonClick: handleOpen,
            },
          ]}
          // buttonSignoutLabel="SignOut"
          buttonLabel="Delete Account"
          onButtonClick={handleIsOpen}
          onSignOutButtonClick={handleLogout}
        />
      </div>

      {/* Delete Modal */}
      <DeleteModal
        open={isOpen}
        handleClose={handleIsClose}
        title="Are You Sure"
        description="You want to delete your account"
        imageSrc={"/assets/delete.svg"}
        isTrue={true}
        width={600}
        height={600}
      >
        <>
          <CustomButton
            text="Yes"
            onClick={handleDeleteAccount}
            width="150px"
            height="50px"
            borderRadius="8px"
            className="bg-[#060531]"
          />

          <CustomButton
            text="No"
            onClick={handleIsClose}
            width="150px"
            height="50px"
            borderRadius="8px"
            className="bg-white border border-gray-300 hover:bg-gray-100"
          />
        </>
      </DeleteModal>

      <CustomModal
        open={open}
        onClose={handleClose}
        title="Premium plan for users"
        closeIcon={true}
      >
        <div className="flex flex-col md:flex-row gap-6 p-6">
          {subscribeCard?.map((val) => {
            return (
              <PlanCard
                id={val?.id}
                title={val?.title}
                price={val?.amount}
                features={featuresList}
                disabled={val?.subscribed === 1 ? true : false}
                buttonText={val?.subscribed === 1 ? "Subscribed" : "Get Plan"}
                titleColor={
                  val?.type === "monthly" ? "text-green-600" : "text-purple-600"
                }
                circleColor={
                  val?.type === "monthly"
                    ? "bg-gradient-to-br from-green-400 to-green-600"
                    : "bg-gradient-to-br from-purple-400 to-purple-600"
                }
                buttonColor={
                  val?.type === "monthly"
                    ? "border border-gray-900 text-gray-900"
                    : "bg-gray-900 text-white"
                }
                onButtonClick={() => {
                  handleCheckoutOpen();
                  handleGetPlan(val?.id);
                }}
              />
            );
          })}
        </div>
      </CustomModal>

      <CustomModal
        open={checkoutOpen}
        onClose={handleCheckoutClose}
        title=""
        closeIcon={false}
      >
        <form onSubmit={onSubmit} className="max-w-md mx-auto p-2 space-y-6">
          <div className="border border-gray-300 p-6 rounded-md">
            <p className="text-[#000000]">Your subscription</p>
            <div className="flex justify-between mt-2">
              <span className="font-semibold text-[#858585]">
                {planDetail?.title} Plan
              </span>
              <span className="text-[#858585]">
                ${planDetail?.amount} Billed {planDetail?.type}
              </span>
            </div>
            {/* Divider */}
            <hr className="my-4" />
            <div className="flex justify-between font-semibold">
              <span className="text-[#000000]">Total</span>
              <span className="text-[#000000]">$15.00</span>
            </div>
          </div>

          <div className="flex flex-col space-x-2">
            {cards?.length > 0 ? (
              <div className="flex flex-col ">
                {cards?.map((card) => (
                  <div
                    key={card.id}
                    className={`border-dashed border-2 p-4 rounded-md flex justify-between items-center mb-3 cursor-pointer ${
                      selectedCard === card.id
                        ? "border-blue-500"
                        : "border-gray-300"
                    } relative`}
                    onClick={() => handleCardSelect(card?.id)}
                  >
                    <button
                      className="absolute top-[-12px] right-[-2px] text-red-500 w-5"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteCard(card?.stripe_source_id);
                      }}
                      type="button"
                    >
                      <MdDelete size={24} />
                    </button>

                    <div className="flex items-center">
                      <img
                        src={
                          card?.card_brand === "Visa"
                            ? "/assets/CardImage/icons8-visa-96.png"
                            : card?.card_brand === "MasterCard"
                            ? "/assets/CardImage/icons8-mastercard-96.png"
                            : card?.card_brand === "Discover"
                            ? "/assets/CardImage/icons8-discover-card-96.png"
                            : card?.card_brand === "American Express"
                            ? "assets/CardImage/icons8-american-express-96.png"
                            : ""
                        }
                        alt={card?.card_brand}
                        className="h-10 w-10 object-contain"
                      />
                      <div className="h-10 border-l-2 border-[#8292BB] mx-3"></div>
                      <div className="ml-3">
                        <p className="text-sm font-semibold text-black">
                          {card?.card_brand}
                        </p>
                        <p className="text-sm text-gray-500">{card?.number}</p>
                      </div>
                    </div>

                    <div className="h-6 w-6 border-2 rounded-full flex items-center justify-center">
                      {selectedCard === card?.id && (
                        <div className="h-3 w-3 bg-[#1D274B] rounded-full"></div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div className="text-center cursor-pointer">
            <p className="text-[#858585]">Or Add Another Card</p>
          </div>
          {/* Card Information */}
          <div className="p-3 border border-gray-300 rounded-md flex justify-between items-center w-full">
            <div className="flex items-center w-full">
              <input
                className="w-full"
                placeholder="Card Number"
                value={cardNumber}
                onChange={handleCardNumberChange}
                style={{
                  fontSize: "16px",
                  color: "#000",
                  letterSpacing: "0.025em",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                }}
              />
              {cardError && <p className="text-red-500">{cardError}</p>}
              <div className="flex space-x-2 ml-2">
                {cardType ? (
                  <p>{cardType}</p>
                ) : (
                  <img
                    src="/assets/card.png"
                    alt="Visa"
                    className="h-10 w-18 object-contain"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex space-x-2">
            <div className="w-1/2">
              <div className="p-5 border border-gray-300 rounded-md">
                <input
                  className="w-full"
                  placeholder="Expiration Date (MM/YY)"
                  value={expDate}
                  onChange={handleExpirationDateChange}
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  }}
                />
                {error && <p className="text-red-500">{error}</p>}
              </div>
            </div>
            <div className="w-1/2">
              <div className="p-5 border border-gray-300 rounded-md">
                <input
                  className="w-full"
                  placeholder="Security Code"
                  value={securityCode}
                  onChange={handleSecurityCodeChange}
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <ButtonWithLoading
            type="submit"
            isLoading={isLoading}
            buttonText={selectedCard ? "Subscribe" : "Add Card"}
            className="custom-button-class"
          />
        </form>
      </CustomModal>
    </Container>
  );
}

export default Settings;
