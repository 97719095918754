import React from "react";

import { AiOutlineClose } from "react-icons/ai";
import { FaImage } from "react-icons/fa";

// import TrashImage from "../../public/assets/delete.svg";

const DeleteModal = ({
  open,
  handleClose,
  imageSrc,
  imageAlt = "Modal Image",
  imageWidth = 200,
  imageHeight = 200,
  title,
  description,
  children,
  width = 410,
  height = 350,
  isTrue = false,
}) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className={`flex flex-col justify-center items-center text-center bg-white shadow-lg rounded-lg p-4`}
        style={{ width: width, maxHeight: height }}
      >
        {!isTrue && (
          <div className="absolute top-3 right-3">
            <button onClick={handleClose} className="text-gray-500">
              <AiOutlineClose size={20} />
            </button>
          </div>
        )}

        {imageSrc && (
          <img
            src={imageSrc}
            alt={imageAlt}
            width={imageWidth}
            height={imageHeight}
            className="object-contain mb-4 p-4"
          />
          // ) : (
          //   <FaImage size={imageWidth} className="mb-4" />
        )}

        <h5
          style={{ fontSize: "2rem", fontWeight: isTrue ? "normal" : "bold" }}
          className="mb-0"
        >
          {title}
        </h5>

        {description && (
          <p
            style={{ fontSize: "2rem", color: isTrue ? "black" : "#999B9F" }}
            className="mb-10"
          >
            {description}
          </p>
        )}

        <div className="flex gap-10 mb-4">{children}</div>
      </div>
    </div>
  );
};

export default DeleteModal;
