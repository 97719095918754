import React from "react";

const Button = ({ divstyle, btnStyle, btnname, type, onPress, disabled, loading }) => {
  return (
    <button
      className={`${divstyle} flex items-center justify-center`} 
      type={type}
      onClick={onPress}
      disabled={disabled || loading} 
    >
      {loading ? (
        <svg
          className="animate-spin h-5 w-5 text-[#050531]" 
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" fill="none" />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 0114.12-4.84A5.5 5.5 0 0012 2 5.5 5.5 0 006.76 8.68 8 8 0 014 12z"
          />
        </svg>
      ) : (
        <p className={btnStyle}>{btnname}</p>
      )}
    </button>
  );
};

export default Button;
