const CustomButton = ({ text, color, onClick, extraClass = '', disabled, loading }) => (
  <button
    onClick={onClick}
    className={`w-full py-2 sm:py-3 rounded-lg text-[18px] font-medium ${color} ${extraClass}`}
    disabled={disabled}
  >
    {text}
  </button>
);

  
  export default CustomButton;