import React from "react";

const CustomModal = ({ open, onClose, children, title, closeIcon }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className={`bg-white rounded-lg shadow-lg p-6 w-full ${closeIcon === true ? "max-w-4xl" : "max-w-2xl"} h-[80vh] overflow-auto`}>
        <div className="flex justify-between items-start mb-6">
          <h1 className="text-2xl font-bold text-gray-900 mt-8 ml-6">
            {title}
          </h1>

          {closeIcon ? (
            <button
              onClick={onClose}
              className="bg-white border border-black rounded-full p-2 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          ) : (
            <button
              onClick={onClose}
              className="bg-[#1D2733] border border-black rounded-full p-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          )}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default CustomModal;
