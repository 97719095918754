import { useState } from "react";
import ComponentHeader from "./Header";

export default function Case({ showLeftCase, caseDetail }) {
  const zoomStates = ["zoom-100", "zoom-90", "zoom-75", "zoom-50", "zoom-25"];
  const [zoom, setZoom] = useState(1);

  return (
    <div className="h-[100%]">
      <div
        className={`${
          !showLeftCase && "hidden"
        }   h-full max-w-screen-md md:w-full `}
      >
        <ComponentHeader setZoom={setZoom} zoom={zoom} />
        {showLeftCase && (
          <div className="flex-1 border-[#4D4D4D] border-2 rounded-md h-[605px] overflow-scroll">
            {/*Header   */}
            {/* Header */}
            <div className={`zoomable ${zoomStates[zoom]}`}>
              <div className="grid grid-cols-1   w-full  rounded-lg p-5 ">
                <h2 className="md:text-xl text-sm font-bold mb-4">
                  {caseDetail?.name}
                </h2>
                <p className="md:text-[12px] text-[8px]  font-normal mb-4">
                  {caseDetail?.description}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
