import React from "react";

import { FaArrowUp } from "react-icons/fa";

import Container from "../../Components/Container";
import CardHeader from "../../Components/CardHeader";
import AvatarComponent from "../../Components/Avatar";
import TextFields from "../../Components/TextField";
import CustomButton from "../../Components/CustomButton";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/slices/AuthSlice";
import { axiosInstance } from "../../api/axios.tsx";
import { Toast } from "../../Components/toast.jsx";

function EditProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const token = localStorage.getItem('token');
  const user = useSelector((state) => state?.Auth?.user);

  const [isLoading, setIsLoading] = React.useState(false);
  const [avatarSrc, setAvatarSrc] = React.useState(
    user?.profile_picture || null
  );

  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarSrc(reader.result);
        formik.setFieldValue("profile_picture", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  async function handleClicked(values) {
    try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("_method", "patch");
        formData.append("first_name", values?.first_name);
        formData.append("last_name", values?.last_name);
        formData.append("email", values?.email);
        formData.append("test_name", values?.test_name);
        formData.append("language", values?.language);
        if (selectedFile) {
            formData.append("profile_picture", selectedFile);
        }

        const response = await axiosInstance.post("/user/update-profile", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        });

        const newImageUrl = response?.data?.response?.profile_image;
        if (newImageUrl) {
            setAvatarSrc(newImageUrl);
        }
        dispatch(setUserData(response?.data?.response?.data));
        Toast({ message: response?.data?.response?.messages[0], isError: false });
        
    } catch (error) {
        Toast({message: error?.response?.data?.error?.messages?.[0], isError: true});
    } finally {
        setIsLoading(false);
    }
}

  const formik = useFormik({
    initialValues: {
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      test_name: user?.test_name || "",
      language: user?.language || "",
      profile_picture: user?.profile_picture,
    },
    onSubmit: async (values, { setSubmitting }) => {
      await handleClicked(values);
      // setSubmitting(false);
    },
  });

  const { values, handleChange, handleSubmit } = formik;

  return (
    <Container>
      <div
        className={`w-full h-full p-3 sm:p-6 md:px-12 md:p-8 bg-[#FFFFFF]`}
        // style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
      >
        <CardHeader header="Profile" onClick={() => navigate(-1)} />

        <div className="space-y-4 mt-2 p-4 flex flex-col justify-center items-center bg-[#FFFFFF] shadow-lg">
          <div className="relative">
            <AvatarComponent
              src={avatarSrc}
              alt="User Avatar"
              size={150}
              onClick={() => document.getElementById("fileInput")?.click()}
            />
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <div
              className={`absolute bottom-0 right-4 transform translate-x-1/6 translate-y-1/6 bg-[#0B54B4] rounded-full p-1`}
            >
              <FaArrowUp size={18} color="#FFFFFF" />
            </div>
          </div>

          <div className="space-y-3 w-full max-w-[600px]">
            <TextFields
              type="text"
              placeholder="Simon"
              icon={"/assets/Group 81765.svg"}
              name="first_name"
              value={values?.first_name}
              onChange={handleChange}
            />
            <TextFields
              type="text"
              placeholder="Lewis"
              name="last_name"
              icon={"/assets/Group 81765.svg"}
              value={values?.last_name}
              onChange={handleChange}
            />
            <TextFields
              type="email"
              placeholder="simon.lewis@gmail.com"
              icon={"/assets/Group 333.svg"}
              name="email"
              value={values?.email}
              onChange={handleChange}
            />
            <TextFields
              type="text"
              placeholder="Test Name"
              icon={"/assets/Group 333.svg"}
              name="test_name"
              value={values?.test_name}
              onChange={handleChange}
            />
            <TextFields
              type="text"
              placeholder="Language"
              icon={"/assets/Group 333.svg"}
              name="language"
              value={values?.language}
              onChange={handleChange}
            />
          </div>

          <div
            className="flex justify-center"
            style={{ marginTop: "2rem", marginBottom: "2rem" }}
          >
            <CustomButton
              text="Update"
              loading={isLoading}
              onClick={handleSubmit}
              width="150px"
              height="50px"
              borderRadius="8px"
              className="bg-[#060531]"
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default EditProfile;
