import { getWithContent, get, post } from "../api/axios.tsx";
import { Toast } from "../Components/toast.jsx";

export default async function getContents({ slug }) {
  try {
    const params = { type: slug };
    const res = await getWithContent({ url:`/business-settings`, params });
    if (res?.data && res?.code === 200) {
      return res?.data;
    } else {
      throw new Error("Request failed");
    }
  } catch (err) {
    console.error("Error fetching listings:", err);
    throw err;
  }
}

export async function getSubscriptionPlans() {
  try {
    const response = await get({
      url: "/subscription-plans",
      includeToken: true,
    });
    console.log(response);
    if (response?.data != null) {
      return response;
    }
  } catch (error) { 
    console.log(error?.message);
  }
}

export async function subscribtionPayment(planId) {
  try {
    const formData = new FormData();
    formData.append("plan_id", planId);
    const response = await post({
      url: `/subscribe`,
      data: formData,
      includeToken: true,
    });

    console.log(response?.messages?.[0]);
    if (response?.data != null) {
      return response;
    }
    Toast({ message: response?.messages?.[0] });
  } catch (error) {
    Toast({ message: error?.message, isError: true });
    // console.log(error?.message);
  }
}

export const getUserSubscriptionStatus = async () => {
  try {
    const data = await getSubscriptionPlans();
    const { response } = data;
    if (response && response.code === 200) {
      const plans = response.data;
      const isSubscribed = plans.some((plan) => plan.subscribed === 1);
      return { subscribed: isSubscribed ? 1 : 0 };
    }
    return { subscribed: 0 };
  } catch (error) {
    console.error("Error fetching subscription status:", error?.message);
    Toast(error?.message);
  }
};

export async function getSubscriptionPlansById(id) {
  try {
    const response = await get({
      url: `/subscription-plans/${id}`,
      includeToken: true,
    });
    console.log(response);
    if (response?.data != null) {
      return response;
    }
  } catch (error) {
    Toast(error?.message);
  }
}

export async function deleteCard(id) {
  try {
    const formData = new FormData();
    formData.append("stripe_source_id", id);
    const response = await post({
      url: `/user/cards/delete`,
      data: formData,
      includeToken: true,
    });

    console.log(response);
    if (response?.data != null) {
      Toast({ message: response?.messages?.[0] });
      return response;
    }
  } catch (error) {
    console.log(error?.message);
  }
}

export async function defaultCardSelect(id) {
  try {
    const formData = new FormData();
    formData.append("stripe_source_id", id);
    const response = await post({
      url: `/user/cards/default`,
      data: formData,
      includeToken: true,
    });

    console.log(response);
    if (response?.data != null) {
      Toast({message: response?.messages?.[0]})
      return response;
    }
  } catch (error) {
    console.log(error?.message);
  }
}

export async function getCards() {
  try {
    const response = await get({
      url: "/user/cards/list",
      includeToken: true,
    });
    console.log(response);
    if (response?.data != null) {
      return response;
    }
  } catch (error) {
    Toast(error?.message);
  }
}

