import { configureStore, combineReducers } from '@reduxjs/toolkit';

import  storage  from 'redux-persist/lib/storage';
import {
  persistReducer,
} from 'redux-persist'

import AuthSlice from "./slices/AuthSlice";
import inputSlice from "./slices/inputSlice";
import subscriptionSlice from "./slices/SubscribeSlice";

const persistConfig={
  key:'root',
  version:1,
  storage,
  timeout: 1000,
}

const reducer = combineReducers({
    Auth: AuthSlice,
    input: inputSlice,
    subscription: subscriptionSlice,

});

const persistedReducer=persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer:persistedReducer,
});



// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
