import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const SettingCard = ({
  header,
  items = [],
  paragraph,
  buttonLabel,
  buttonSignoutLabel,
  onSignOutButtonClick,
  onButtonClick,
}) => {
  return (
    <div className="p-6 space-y-6 bg-white shadow-lg">
      {header && (
        <h2 className="text-black text-[24px] font-semibold mb-4">{header}</h2>
      )}

      {paragraph ? (
        <p
          className="text-[30px] text-[#1D2733] font-medium"
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
      ) : (
        items.map((item, index) => (
          <div
            key={index}
            className="flex justify-between items-center py-2 border-t border-gray-100 cursor-pointer"
            onClick={item.onButtonClick ? item.onButtonClick : undefined}
          >
            {item.path ? (
              <Link
                to={item.path}
                className="flex justify-between items-center w-full"
              >
                <span className="font-medium text-[20px]">{item.title}</span>
                {item.icon || <AiOutlineArrowRight size={20} color="#000000" />}
              </Link>
            ) : (
              <>
                <span className="font-medium text-[20px]">{item.title}</span>
                {item.icon || <AiOutlineArrowRight size={20} color="#000000" />}
              </>
            )}
          </div>
        ))
      )}

      {buttonLabel && (
        <>
          {/* <button
            className="w-full text-lg font-medium py-1 text-black text-left border-t border-gray-50"
            onClick={onSignOutButtonClick}
          >
            {buttonSignoutLabel}
          </button> */}
          <button
            className="w-full text-[20px] font-medium py-4 text-red-500 text-left border-t border-gray-50"
            onClick={onButtonClick}
          >
            {buttonLabel}
          </button>
        </>
      )}
    </div>
  );
};

export default SettingCard;
