import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  answers: {},
};

export const inputSlice = createSlice({
  name: 'input',
  initialState: initialState,
  reducers: {
    saveAnswer: (state, action) => {
      const { questionId, data } = action.payload;
      state.answers[questionId] = data?.answers; 
    },
  },
});

export const { saveAnswer } = inputSlice.actions;
export default inputSlice.reducer;
